.mx-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1438px;
}

@media (max-width: 3000px) and (min-width: 1501px) {
  .mx-container {
    max-width: 1438px !important;
  }
}
@media (max-width: 1500px) and (min-width: 1401px) {
  .mx-container {
    max-width: 1300px !important;
  }
}
@media (max-width: 1400px) and (min-width: 1281px) {
  .mx-container {
    max-width: 1200px !important;
  }
}

@media (max-width: 1280px) and (min-width: 1181px) {
  .mx-container {
    max-width: 1150px !important;
  }
}

@media (max-width: 1180px) and (min-width: 1024px) {
  .mx-container {
    max-width: 1050px !important;
  }
}

@media (max-width: 1023px) and (min-width: 769px) {
  .mx-container {
    max-width: 1024px !important;
  }
}

@media (max-width: 768px) and (min-width: 641px) {
  .mx-container {
    max-width: 768px !important;
  }
}

@media (max-width: 640px) and (min-width: 320px) {
  .mx-container {
    max-width: 640px !important;
  }
}
